<template>
  <div class="admission-quiz">
    <div class="admission-quiz__header">
      <span class="admission-quiz__header-title">Quiz d'admission</span>
      <span class="admission-quiz__header-indicator">Question {{currentQuestionPos + 1}} / {{questions.length}}</span>
    </div>
    <div class="admission-quiz__body">
      <p class="admission-quiz__body-question">{{questions[currentQuestionPos].value}}</p>
      <div class="admission-quiz__body-answers">
        <template v-if="questions[currentQuestionPos].type == 'radio'">
          <div v-for="(option, index) in questions[currentQuestionPos].options" :key="`option-${index}`" class="admission-quiz__body-answer">
            <input 
              :id="option" 
              class="admission-quiz__body-answer-input" 
              type="radio" 
              name="answer-1" 
              :value="option"
              v-model="questions[currentQuestionPos].answer"
            >
            <label class="admission-quiz__body-answer-label" :for="option">{{option}}</label>
          </div>
        </template>
        <template v-else>
          <textarea 
            placeholder="Votre réponse ..."
            class="admission-quiz__body-answer-textarea"
            v-model="questions[currentQuestionPos].answer"
          ></textarea>
        </template>
      </div>
    </div>
    <button v-if="currentQuestionPos + 1 < questions.length" class="admission-quiz__button" @click="currentQuestionPos++">Suivant</button>
    <button v-else class="admission-quiz__button" @click="submitRegistrationRequest">Soumettre</button>
  </div>
</template>

<script>
  import {ref, computed} from 'vue';
  import {useStore} from 'vuex';
  import {useRouter} from 'vue-router';
  import { required } from '@vuelidate/validators';
  import { useVuelidate } from '@vuelidate/core';
  export default {
    setup() {
      const {commit, getters, dispatch, state} = useStore();
      const router = useRouter();
      const newUser = getters['users/newUser'];
      // List of question
      const questions = ref([
        {
          value:"Quelle est date d'entrée en vigueur de la loi organique ?",
          type:'radio',
          options:['2011','2013','2015'],
          answer:''
        },
        {
          value:"Quels sont les 8 documents d'information budgétaire ?",
          type:'text',
          answer:''
        },
        {
          value:"De quoi est composé le budget citoyen ?",
          type:'text',
          answer:''
        },
        {
          value:"Qu'elle est la date d'édition du budget citoyen ?",
          type:'text',
          answer:''
        },
        {
          value:"Qu'elle est la particularité de la loi organique ?",
          type:'text',
          answer:'' 
        },
        {
          value:"Qui sont les commissions parlementaires qui veillent sur la transparence budgétaire ?",
          type:'text',
          answer:''
        },
        {
          value:"Quels sont les acteurs qui interviennent dans les processus budgétaires de l'État ?",
          type:'text',
          answer:''
        },
      ]);
      // Variable to keep track of the current question number
      const currentQuestionPos = ref(0);
      // Function to extract questions and their answers (elminiate the 'options' field)
      const questionsAnswers = () => {
        return questions.value.map ((question) => {
          return {question:question.value, answer: question.answer}
        });
      };
      // Submit registration request
      const submitRegistrationRequest = async () => {
        // Upload avatar if exists
        commit('posts/SET_LOADING',true);
        // const avatar = state.files.files;
        // if (avatar) {
        //   let uploadedAvatar = await dispatch("files/upload", avatar);
        //   newUser.avatar = uploadedAvatar[0].id;
        //   commit(`files/${filesMutationTypes.SET_FILES}`, []);
        // }
        // Register the user
        const registeringUser = {
          ...newUser,
          answers:questionsAnswers()
        }
        try {
          // Send request to register user
          const user = await dispatch('auth/registerUser', registeringUser);
          // Redirect user to congratulations page 
          router.push({name:'Congratulations'})
        } catch (error) {
        }
      } 
     return {
        questions,
        currentQuestionPos,
        questionsAnswers,
        submitRegistrationRequest
      }
    }
  }
</script>

<style lang="scss" scoped>
  .admission-quiz{
    @apply bg-white w-full rounded-md
    pt-5 pb-5 px-6
    lg:px-[118px] lg:pt-8 lg:pb-[58px];
    &__header{
      @apply flex justify-between 
      text-base leading-6 font-normal
      lg:text-[18px] lg:font-medium lg:leading-7;
      &-title{
        @apply text-black;
      }
      &-indicator{
        @apply text-primary;
      }
    }
    &__body{
      @apply mt-6 lg:mt-[60px];
      &-question{
        @apply text-base leading-6 mb-3
        lg:text-[24px] lg:mb-[54px];
      }
      &-answers{

      }
      &-answer{
        @apply flex items-center mb-6;
        &-input{

        }
        &-textarea{
          @apply w-full h-[182px]
          py-[10px] px-2
          mb-[37px]
          border border-[#D1D5DB];
          &::placeholder{
            @apply text-[#111827] text-base;
          }
        }
        &-label{
          @apply ml-2 text-base leading-6
          cursor-pointer
          lg:ml-3 lg:text-[24px];
        }
      }
    }
    &__button{
      @apply w-full bg-primary text-white rounded-md
      font-medium leading-6
      py-2;
    }
  }
</style>